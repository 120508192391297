/*
---

name: App

requires: [Util/Util]

...
*/

var bar = foo();
