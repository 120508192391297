/*
---

name: Util

provides: [Util]

...
*/

function foo() {}
